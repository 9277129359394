const getLogoMinOrder = (selectedProduct) => {
    let minOrder = 0;

    if (selectedProduct.variants && selectedProduct.variants.length > 0) {
        return selectedProduct.variants[0].selectedOptions.find(s => s.name.toLowerCase() === 'staffel').value;
    }

    return minOrder;
};

const getLogoPricePerBall = (selectedProduct, currentVariant) => {
    const ballsPerPackage = Number.parseInt(selectedProduct.metafields.find(m => m.key === 'ballsPerPackage')?.value);

    if (!ballsPerPackage) {
        return 0;
    }

    return currentVariant.price / ballsPerPackage;
};

const getLogoBallsPerPackage = (selectedProduct) => {
    return Number.parseInt(selectedProduct.metafields.find(m => m.key === 'ballsPerPackage')?.value);
}

export { getLogoMinOrder, getLogoPricePerBall, getLogoBallsPerPackage };
