import React from 'react';
import * as styles from './logo-product-element.module.scss';

import GsButton from "../../page-components/buttons/gsButton";
import GsProductPrice from "../../page-components/product/gsProductPrice";

const LogoProductElement = ( {
                                 className = '',
                                 key,
                                 product,
                                 tag,
                                 stagged = false,
                                 onClick = () => { }
                             } ) => {

    const ballsPerPackage = product.metafields.find(m => m.key === "ballsPerPackage")?.value;

    return <div key={key} className={"d-flex " + className + " " + (stagged ? "flex-column" : "")}>

        { product.images?.length > 0 &&
            <img className={styles.image + " " + (stagged ? "mx-auto mb-3" : "") + " object-fit-contain"} src={product.images[0].originalSrc} />
        }

        <div className={"ps-3"}>
            <h4>{product.title}</h4>

            <div
                className={styles.description + " mb-3"}
                dangerouslySetInnerHTML={
                {
                    __html: product.descriptionHtml
                }
            } />


            {ballsPerPackage > 0 &&
                <>
                    <GsProductPrice product={product} showFromTo={true}/> pro Packung ({ballsPerPackage} Bälle)
                    <br />
                </>
            }
            <GsButton onClick={ () => onClick(product)} className={"mt-2"} variant={"primary"}>Auswählen</GsButton>

        </div>
    </div>

};

export default LogoProductElement;
