import React, { useEffect, useRef, useState } from 'react';
import * as styles from "./logo-product.module.scss";
import LogoProductElement from "./parts/logo-product-element";
import ChevronRightSVG from "../icons/fontawesome/light/chevron-right.svg";
import { getLogoBallsPerPackage, getLogoMinOrder, getLogoPricePerBall } from "../helper/product/helperLogoproduct";
import { gsCurrencyPipe } from "../helper/gsCurrencyPipe";
import GsOption from "../page-components/option/gsOption";
import GsButton from "../page-components/buttons/gsButton";
import GsProductDeliveryTime from "../page-components/product/gsProductDeliveryTime";
import { useStoreContext } from "../provider/storeProvider";
import {graphql, navigate, useStaticQuery} from "gatsby";
import { getStrapiUrl } from "../helper/gsConstants";
import * as axios from "axios";

function createObjectURL(object) {
    return (window.URL) ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(object);
}

const LogoProductConfigurator = ( { collectionTitle = '', products, isLogoBall = true } ) => {

    const storeContext = useStoreContext();

    const part3Ref = useRef();

    const [ selectedProduct, setSelectedProduct ] = useState(null);
    const [ selectedAmount, setSelectedAmount ] = useState(0);

    const [ currentVariant, setCurrentVariant ] = useState(0);
    const [ currentVariantIndex, setCurrentVariantIndex ] = useState(0);

    const [ currentColor, setCurrentColor ] = useState(null);
    const [ hasColor, setHasColor ] = useState(false);

    const [ uploadedFile, setUploadFile ] = useState(null);
    const [ image, setImage ] = useState(null);

    const [ showAllBalls, setShowAllBalls ] = useState(false);

    const uniqueVendors = (products.map(p => p.vendor)).filter( (p, i, array) => {
        return array.indexOf(p) === i;
    } );

    const [ vendors, setVendors ] = useState( uniqueVendors);
    const [ filterVendor, setFilterVendor ] = useState(null);
    const [ filterResult, setFilterResult ] = useState([]);

    const [ minimumError, setMinimumError ] = useState(false);

    useEffect(
        () => {
            const otherOnes = products.filter( p => p !== premiumProduct && p !== recommendedProduct && p !== budgetProduct);

            if (filterVendor) {
                setFilterResult(otherOnes.filter(o => o.vendor === filterVendor));
            } else {
                setFilterResult(otherOnes);
            }
        },
        [ filterVendor ]
    );

    useEffect(
        () => {
            handleSelectedAmountChange(null);
        },
        [ currentColor ]
    );

    const onFileChange = async (event) => {
        if (event.target.files?.length > 0) {
            setUploadFile(event.target.files?.length > 0 ? event.target.files[0] : null);

            const src = createObjectURL(event.target.files[0]);
            setImage(src);

            const form = new FormData();
            form.set('logo', event.target.files[0]);

            try {
                await axios.post(getStrapiUrl() + "/logo-upload", form);
            } catch(err) {
                window.alert("Ihr Logo konnte nicht erfolgreich hochgeladen werden");
            }
        }
    };

    const premiumProduct = products.find( p => p.tags.find(t => t.toLowerCase() === 'premium'));
    const recommendedProduct = products.find( p => p.tags.find(t => t.toLowerCase() === 'empfohlen' ));
    const budgetProduct = products.find( p => p.tags.find(t => t.toLowerCase() === 'budget'));

    const otherProducts = products.filter( p => p !== premiumProduct && p !== recommendedProduct && p !== budgetProduct);

    const handleProductClick = (product) => {
        setSelectedProduct(product);
        setSelectedAmount(product.variants[0].selectedOptions.find(o => o.name.toLowerCase() === 'staffel')?.value);

        setCurrentVariant(product.variants[0]);
        setCurrentVariantIndex(0);

        if (product.options.find(o => o.name.toLowerCase() === 'farbe')) {
            setHasColor(true);
            setCurrentColor(product.options.find(o => o.name.toLowerCase() === 'farbe').values[0]);
        }

        if (part3Ref.current) {
            part3Ref.current.scrollIntoView( { left: 0, block: 'start', behavior: 'smooth' });
        }
    }

    const handleSelectedAmountChange = (event) => {
        // transfer the original
        let amount = selectedAmount;

        if (event) {
            amount = Number.parseInt(event.target.value);
            setSelectedAmount(amount);
        }

        if (!selectedProduct) {
            return;
        }


        let availableVariants = selectedProduct.variants;

        if (hasColor) {
            availableVariants = selectedProduct.variants.filter(
                v => v.selectedOptions.find(o => o.name.toLowerCase() === 'farbe')?.value === currentColor
            );
        }

        const minimum = selectedProduct.variants[0].selectedOptions.find(o => o.name.toLowerCase() === 'staffel')?.value;

        if (amount < minimum) {
            setMinimumError(true);
            return;
        } else {
            setMinimumError(false);
        }

        // find variant
        let idx = 0;
        let variant = availableVariants[0];

        for (; idx < availableVariants.length; idx++) {
            const v = availableVariants[idx];
            const staffel = v.selectedOptions.find(o => o.name.toLowerCase() === 'staffel')?.value;

            if (amount < staffel) {
                idx--;
                break;
            }

            variant = v;
        }

        setCurrentVariantIndex(idx);
        setCurrentVariant(variant);

        setSelectedAmount(amount);

    };

    const handleAddToCart = async (event) => {

        await storeContext.addVariantToCart(
            currentVariant.shopifyId,
            selectedAmount,
            [
                {
                    key: 'protected',
                    value: 'true'
                },
                {
                    key: 'balls',
                    value: ( getLogoBallsPerPackage(selectedProduct) * selectedAmount).toString() || ""
                },
                {
                    key: 'packages',
                    value: selectedAmount?.toString() || ""
                },
                {
                    key: 'color',
                    value: currentColor.toString() || ""
                }
            ]
        );

        await navigate("/warenkorb");
    }

    return <div className="row">
        <div className="col-12 px-lg-5">

            <div className={styles.step + " p-3 p-xl-5 mb-5"}>
                <h2>1 - Logo hochladen</h2>
                <p>
                    Nützen Sie unseren Datei-Upload um uns Ihr gewünschtes Logo etc. direkt zu schicken.
                    <br />
                    Alle gängigen Dateiformate werden unterstützt, wenn möglich in hoher Auflösung bitte hochladen.
                    Sollte die Datei doch nicht passen, melden wir uns bei Ihnen und unterstützten Sie.
                </p>

                <div className="d-flex mt-5 flex-wrap">
                    <div className="col-12 col-lg-8">

                        <form>
                            <div className="form-control">
                                <label htmlFor="logoUpload">

                                    <b>Logo hochladen</b>

                                </label>

                                <input id={"logoUpload"} name={"logoUpload"} type={"file"}
                                       onChange={onFileChange} />
                            </div>
                        </form>

                    </div>


                    <div className="col-lg-4 col-12">

                        {!image &&
                        <div
                            className={styles.logoBox + " d-flex align-items-center justify-content-center text-center"}>
                            Bitte laden Sie Ihr Logo hoch
                        </div>
                        }

                        {
                            image && <>
                                <img src={image} className={"img-fluid"} />
                            </>
                        }

                    </div>
                </div>

            </div>

            <div className={styles.step + " p-2 p-lg-5 mb-5"}>
                {isLogoBall && <h2>2 - Ball auswählen</h2> }
                {!isLogoBall && <h2>2 - Variante auswählen</h2> }

                { isLogoBall && <>
                <div className="row mb-5">
                    <div className="col-12 col-xl-4 col-lg-6">
                        <div className={styles.box}>
                            <h5>Premium</h5>
                            { premiumProduct && <LogoProductElement stagged={true} key={'premium'} onClick={handleProductClick} product={premiumProduct} tag={'premium'} /> }
                        </div>
                    </div>

                    <div className="col-12 col-xl-4 col-lg-6 mt-5 mt-lg-0">
                        <div className={styles.box}>
                            <h5>Preis/Leistung</h5>
                            { recommendedProduct && <LogoProductElement stagged={true} key={'recommended'} onClick={handleProductClick} product={recommendedProduct} tag={'recommended'} /> }
                        </div>
                    </div>

                    <div className="col-12 col-xl-4 col-lg-6 mt-5 mt-lg-0">
                        <div className={styles.box}>
                            <h5>Budget</h5>
                            { budgetProduct && <LogoProductElement stagged={true} key={'budget'} onClick={handleProductClick} product={budgetProduct} tag={'budget'} /> }
                        </div>
                    </div>
                </div>

                { !showAllBalls && <GsButton variant={"accent"}
                                             onClick={ () => setShowAllBalls(true) }
                                             className={"d-inline-flex align-items-center"}>
                    <ChevronRightSVG className={"me-1 svgFill"} />
                    Alle Bälle anzeigen
                </GsButton>
                }

                {showAllBalls && vendors?.length > 1 &&
                <div className={"form-control mt-5"}>

                    <label>Hersteller</label>
                    <select
                        value={filterVendor}
                        onChange={(e) => setFilterVendor(e.target.value)}>
                        <option value={""} defaultChecked>Alle</option>
                        {vendors.map(v => <option>{v}</option>)}
                    </select>

                </div>
                }

                { showAllBalls && filterResult.map(
                    p => {
                        return <LogoProductElement className={"my-5"} key={p.handle} onClick={handleProductClick} product={p} />
                    }
                )}
                </>}

                {
                    !isLogoBall &&
                    <>
                        {filterResult.map(
                            p => <LogoProductElement className={"my-5"} key={p.handle} onClick={handleProductClick} product={p} />
                        )}
                    </>
                }

            </div>

            <div id={"step3"} ref={part3Ref}>
                {selectedProduct &&
                <div className={styles.step + " p-5 mb-5"}>
                    <h3>3 - In Warenkorb legen</h3>

                    <h4 className={"text-accent mt-4"}>{selectedProduct.title}</h4>
                    {isLogoBall &&
                    <p>Mindestbestellmenge: {getLogoMinOrder(selectedProduct)} Schachteln</p>
                    }

                    {!isLogoBall &&
                    <p>Mindestbestellmenge: {getLogoMinOrder(selectedProduct)} {collectionTitle}</p>
                    }

                    {isLogoBall &&
                    <p>Bälle pro Schachtel: {getLogoBallsPerPackage(selectedProduct)}</p>
                    }

                    <table className={"table mt-4"}>
                        <thead>
                        <tr>
                            <td>Menge</td>
                            <td>Farbe</td>

                            {isLogoBall &&
                            <td>
                                Preis pro Schachtel
                            </td>
                            }

                            {!isLogoBall &&
                            <td>
                                Preis pro
                            </td>
                            }

                            {getLogoBallsPerPackage(selectedProduct) > 0 &&
                            <td>
                                Preis pro Ball
                            </td>
                            }
                        </tr>
                        </thead>

                        { selectedProduct.variants.map(
                            (variant, i) => {
                                return <tr className={ (variant === currentVariant ? "text-primary" : "") }>
                                    <td>
                                        ab&nbsp;
                                        { variant.selectedOptions.find(o => o.name.toLowerCase() === 'staffel')?.value }
                                        { isLogoBall && <> Schachteln</> }
                                        { !isLogoBall && <> {collectionTitle}</> }
                                    </td>

                                    <td>
                                        { variant.selectedOptions.find(o => o.name.toLowerCase() === 'farbe') ?
                                            variant.selectedOptions.find(o => o.name.toLowerCase() === 'farbe').value : "Standard (Weiß)"
                                        }
                                    </td>

                                    <td>
                                        { gsCurrencyPipe(variant.price) }
                                    </td>

                                    {getLogoBallsPerPackage(selectedProduct) > 0 &&
                                    <td>
                                        {gsCurrencyPipe(getLogoPricePerBall(selectedProduct, variant))}
                                    </td>
                                    }
                                </tr>
                            }
                        )}



                    </table>

                    <div className={"mt-5"}>

                        <div className={styles.amountInput + " form-control"}>
                            { getLogoBallsPerPackage(selectedProduct) > 1 &&
                                <label>Menge (Schachteln) - { selectedAmount * getLogoBallsPerPackage(selectedProduct) } Bälle </label>
                            }

                            {
                                !getLogoBallsPerPackage(selectedProduct) &&
                                    <label>Menge</label>
                            }

                            <input type={"number"} value={selectedAmount} onChange={handleSelectedAmountChange} />
                        </div>

                        {hasColor &&
                        <div className={"form-control my-2"}>
                            <label>Farbe</label>
                            {selectedProduct.options.find(o => o.name.toLowerCase() === 'farbe').values.map(
                                v => <GsOption isChecked={v === currentColor}
                                               className={"c-pointer me-2 " + styles.optionBtn}
                                               onClick={() => {
                                                   setCurrentColor(v);
                                               }
                                               }>
                                    {v}
                                </GsOption>
                            )}
                        </div>
                        }


                        { selectedAmount > 0 &&
                        <div className={styles.info + " mb-4"}>
                            Gesamt: { gsCurrencyPipe(currentVariant.price * selectedAmount) } inkl. 20% Ust<br />
                            länderspezifischer Preis wird im Checkout berechnet
                        </div>
                        }

                        { !minimumError &&
                        <GsButton variant={"primary"}
                                  onClick={handleAddToCart}>
                            In den Warenkorb legen
                        </GsButton>
                        }

                        { minimumError &&
                            <div className={"tw-font-bold tw-text-lg tw-text-red-900"}>
                                Die Mindestbestellmenge ist { selectedProduct?.variants[0]?.selectedOptions.find(o => o.name.toLowerCase() === 'staffel')?.value }
                            </div>
                        }


                        <GsProductDeliveryTime product={products[0]} className={"mt-3"} overwriteIsLogo={true} />
                    </div>
                </div>
                }
            </div>



        </div>
    </div>;

};

export default LogoProductConfigurator;
